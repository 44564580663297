//接口请求域名
var API_URL = "api/";
if(process.env.NODE_ENV == 'development'){
	API_URL = "api/api/";
}else{
	API_URL = "api/";
}
//接口地址
export default{
		getbasedatalist:API_URL + "pms/basedoc",//基础档案列表
		getbasedatainfo:API_URL + "pms/basedocinfo",//基本档案数据
		getemailcode:API_URL + "login/admin/emailCode",//邮箱验证码
		searchMem:API_URL + 'pms/member/quickQueryMember',
    	loginUrl: API_URL + 'login/admin',
    	ownerData:API_URL + 'member/login/index',
    	safeCodeUrl: API_URL + 'mss/smsCode',
		getMenu:API_URL + "menu/list",
    	refreshToken: API_URL + 'login/adminRefreshToken',
		logout: API_URL + 'login/admin/loginOut',//退出登录
		getpagepms:API_URL + 'pms/page',
		getService:API_URL + "admin/hotel/service",//查询设施
		hotelOrderlist:API_URL + "admin/hotel/order/list",//订单列表
		getroomtypelist:API_URL + "pms/roomtypelist",//获取房间类型列表
		deleteroomtype:API_URL + "pms/roomtypeaggdelete",//删除房间类型
		roomtypebyparentid:API_URL + "pms/roomtypebyparentid",//获取房间类型数据
		gethotellist:API_URL + "pms/hotellist",//获取旅馆列表
		gethotelbyparentid:API_URL + "pms/hotelinfobyparentid",//获取房间类型数据
		getroomstylelist:API_URL + "pms/roomstyle",//获取房间风格列表
		getbedtypelist:API_URL + "pms/bedtype",//获取床型列表
		hotelupload:API_URL + "pms/upload",//上传图片
		hotelupload2:API_URL + "pms/upload2",//上传图片2
		getinoutref:API_URL + "pms/inoutref",//入住退房时间
		getinouttime:API_URL + "pms/inouttime",//入住退房时间
		getinouteffectref:API_URL + "pms/inouteffectref",//入住退房有效期
		saveroomtype:API_URL + "pms/roomtypeaggsave",//保存房间类型
		getbookendref:API_URL + "pms/bookendref",//预约期限
		hotelinfoaggsave:API_URL + "pms/hotelinfoaggsave",//保存房源
		setUserLang:API_URL + "pms/setUserLang",//设置多语
		// hotelinfobatchaggsave:API_URL + "hotel/pms/hotelinfobatchaggsave",//同类型房间增加
		getpriceplanlist:API_URL + "pms/priceplan",//费用计划列表
		getbookorderlist:API_URL + "pms/bookingorder/list",//获取订单列表
		getbookorderdetail:API_URL + "pms/bookingorder/byparentid",//获取订单详情
		changehotelstatus:API_URL + "pms/hotelstatus",//改变状态
		bookingorderaggsave:API_URL + "pms/bookingorder/save",//保存订单
		bookingorderaggsaveV3:API_URL + "pms/bookingorder/save/v3",//保存订单
		getpaymentstatus:API_URL + "pms/orderpaymentstatus",//获取支付状态
		getorderstatus:API_URL + "pms/orderstatus",//获取订单状态
		getsitelist:API_URL + "pms/site",//平台列表
		gethotelventorybydate:API_URL + "pms/admin/hotelventorybydate",//房源日历
		getbookingcalendarbydate:API_URL + "pms/admin/bookingcalendarbydate ",//预定日历
		gethotelroomtypes:API_URL + "pms/hotelroomtypes",//获取房源下房间类型
		getdiscount:API_URL + "pms/memberdiscount",//获取折扣
		deletediscount:API_URL + "pms/memberdiscountdelete",//获取折扣
		savediscount:API_URL + "pms/memberdiscountsave",//保存折扣
		deletehotel:API_URL + "pms/hotelinfoaggdelete",//删除旅馆
		deleteroominfo:API_URL + "pms/roominfoaggdelete",//删除房间
		deletebookingorder:API_URL + "pms/bookingorderaggdelete",//删除订单
		getorderroombyid:API_URL + "pms/bookingorderroombyid",//房间数据
		priceplanaggsave:API_URL + "pms/priceplanagg/save",//保存费用计划
		priceplanaggdelete:API_URL + "pms/priceplanaggdelete",//删除费用计划
		gettaginfo:API_URL + "pms/taginfo",//获取标签信息
		taginfosave:API_URL + "pms/taginfosave",//新增标签
		taginfodelete:API_URL + "pms/taginfodelete",//删除标签
		getsynsetting:API_URL + "pms/synsetting",//同步设定
		gethoteldetail:API_URL + "pms/hotelinfo/byparentid",//获取旅馆详情
		roompriceplansave:API_URL + "pms/roompriceplansave",//房间费用计划保存
		roompriceplandelete:API_URL + "pms/roompriceplandelete",//删除费用计划
		getfeeplanbyroom:API_URL + "pms/priceplanagg/byRoomId",//获取房间费用计划
		getfeeplanbyroomtype:API_URL + "pms/hotelRoomTypePricePlan/list",//获取房间类型费用计划
		getEnableHotel:API_URL + "pms/isEnableHotel",//获取可用房源
		gethotelCanUseRooom:API_URL + "pms/hotelCanUseRooom",//获取可用房间
		changeroomstate:API_URL + "pms/roomIsEnable",//更改状态
		gethotelRoomTypeGroup:API_URL + "pms/hotelRoomTypeGroup",//获取同类型房间列表
		roomTypePricePlanBatch:API_URL + "pms/roomTypePricePlanBatch",//房间类型费用计划
		getroominfobyparentid:API_URL + "pms/roominfobyparentid",//获取房间详情
		roominfoaggsave:API_URL + "pms/roominfoaggsave",//保存房间信息
		getorderprice:API_URL + 'pms/order/extraPerson',//获取订单金额
		getroomorderprice:API_URL + 'pms/order/room/extraPerson',//获取订单金额按房间
		cancelOrder:API_URL + 'pms/book/cancelOrder',//取消订单
		refundapply:API_URL + 'pms/order/refund/apply',//退款
		getbooklog:API_URL + 'pms/book/log',//获取操作记录
		getorderReport:API_URL + 'pms/book/orderReport',//获取报表
		getpriceTemplatelist:API_URL + 'pms/priceTemplate/list',//费率计划
		getratedetail:API_URL + 'pms/priceTemplate/rate/list',//费率详情
		saverateTemplate:API_URL + 'pms/priceTemplate/rate/save',//保存费率
		changetemplatename:API_URL + 'pms/priceTemplate/save',//修改模板名字
		applypriceTemplate:API_URL + 'pms/priceTemplate/apply',//应用模板
		getpricehotellist:API_URL + 'pms/priceTemplate/hotel/list',//获取所有旅馆
		getapplyhistory:API_URL + 'pms/priceTemplate/apply/history',//获取应用记录
		deletepriceTemplate:API_URL + 'pms/priceTemplate/delete',//删除模板
		pullAirhostOrder:API_URL + 'pms/book/pullAirhostOrder',//拉取airhost数据
		roomTypePriceUpdate:API_URL + 'pms/roomTypePriceUpdate',//更新价格
		changeorderroom:API_URL + 'pms/book/updateroom',//变更房间
		selectHotelReception:API_URL + 'pms/selectHotelReception',//查询房源担当 GET
		setHotelReception:API_URL + 'pms/setHotelReception',//设置房源担当
		hotelReceptionList:API_URL + 'pms/hotelReceptionList',//当前登陆用户管理的房源
		getreceptionUser:API_URL + "pms/receptionUser", //获取担当列表
		getroomCalendarbydate:API_URL + "pms/roomCalendarbydate", //房间预定信息
		checkedRoom:API_URL + "pms/book/checked", //入住状态
		savepassword:API_URL + "pms/roomLock/save", //保存密码
		adminOpenLock:API_URL + "pms/roomLock/adminOpenLock ", //开锁
		getroomLockByRoomId:API_URL + "pms/roomLock/selectByRoomId", //获取房间密码
		getcleanTaskByDate:API_URL + "pms/cleanTaskByDate", //获取清扫任务
		cleanTaskSave:API_URL + "pms/cleanTaskSave", //保存清扫任务
		cleanTaskSaveCheck:API_URL + "pms/cleanTaskSaveCheck", //检测清扫
		cleanTaskDelete:API_URL + "pms/cleanTaskDelete", //删除清扫任务
		setrefundStrategy:API_URL + "pms/hotel/save/refundStrategy", //保存退款规则
		getcompanylist:API_URL + "pms/company/list", //公司列表
		savecompany:API_URL + "pms/company/save", //公司列表
		recharge:API_URL + "pms/company/recharge", //充值
		getamountLogList:API_URL + "pms/company/amountLogList", //消费记录列表
		getcouponslist:API_URL + "pms/coupons/listV2", //优惠券列表
		gethotelEmailTemp:API_URL + "pms/hotelEmailTemp/list", //获取邮件模板
		gethotelEmailTempById:API_URL + "pms/hotelEmailTemp/hotelEmailTempById", //详情
		getTemporderStatus:API_URL + "pms/hotelEmailTemp/orderStatus", //订单状态
		savehotelEmailTemp:API_URL + "pms/hotelEmailTemp/save", //保存模板
		gettempParam:API_URL + "pms/hotelEmailTemp/tempParam", //查询参数
		getTimeBased:API_URL + "pms/hotelEmailTemp/timeBased", //时间点
		getLabellist:API_URL + "pms/label/list", //获取标签列表
		bindLabel:API_URL + "pms/coupons/bindLabel", //绑定标签
		savecoupons:API_URL + "pms/coupons/saveV2", //保存优惠券
		getlabelUserList:API_URL + "pms/label/userList", //用户列表
		getcouponuser:API_URL + "pms/coupons/userList", //优惠券列表
		deleteMemberLabel:API_URL + "pms/member/deleteMemberLabel", //删除用户
		savelabel:API_URL + "pms/label/save", //保存标签
		deleteCouponsLabel:API_URL + "pms/label/deleteCouponsLabel", //删除优惠券
		gtjMembersByLevel:API_URL + "pms/member/gtjMembersByLevel", //查询会员
		gtjMembersLevel:API_URL + "pms/member/gtjMembersLevel", //查询会员
		membersBindLabel:API_URL + "pms/member/gtjMembersBindLabel", //标签绑定会员
		gethotelRoomtypelist:API_URL + "pms/hotelRoomtypelist", //获取房源下房间
		getcleanTable:API_URL + "pms/cleanTable", //清扫日历
		getdiscountlist:API_URL + "pms/discount/list", //折扣列表
		saveHoteldiscount:API_URL + "pms/discount/save", //追加折扣
		getdiscountlist2:API_URL + "pms/discount/listV2", //折扣列表2
		saveHoteldiscount2:API_URL + "pms/discount/saveV2", //追加折扣2
		couponsgrant:API_URL + "pms/coupons/grant", //发放优惠券
		getmemlist:API_URL + "pms/member/list", //查询会员列表
		hotelPriceReserve:API_URL + "pms/hotelPriceReserve",
		hotelPriceReservedate:API_URL + "pms/hotelPriceReserveByDate",
		finishbookingorder:API_URL + "pms/bookingorder/finish",//变为已支付
		getpageTemplateList:API_URL + "pms/pageTemplateList",//模版列表
		getpageTemplateDetailList:API_URL + "pms/pageTemplateDetailList",//模板详情列表
		getrcMultilangList:API_URL + "pms/rcMultilangList",//多语详情列表
		rcMultilangSaveOrUpdate:API_URL + "pms/rcMultilangSaveOrUpdate",//维护多语
		pageTemplateDetailSaveOrUpdate:API_URL + "pms/pageTemplateDetailSaveOrUpdate",//编辑模板详情
		pageTemplateSaveOrUpdate:API_URL + "pms/pageTemplateSaveOrUpdate",//保存模版
		updatepageTemplate:API_URL + "pms/pageTemplate",//更新模板组
		appendResourceToCache:API_URL + "pms/appendResourceToCache",//更新内容
		updatetoProduction:API_URL + "pms/updateProduction",//更新到生产环境
		refundReject:API_URL + "pms/bookingorder/refundReject",//驳回取消
		orderapproval:API_URL + "pms/email/cancel/order/approval",//取消
		lockinventory:API_URL + "pms/inventory/lock",//锁房
		getlockstatus:API_URL + "pms/inventory/lock/status",//查询状态
		savehomepage:API_URL + "pms/homePage/save",//首頁活動保存
		saveagghomepage:API_URL + "pms/homePage/agg/save",//首頁活動保存agg
		deletehomepage:API_URL + "pms/homePage/delete",//刪除首頁活動
		gethomepagelist:API_URL + "pms/homePage/list",//活動列表
		getpromotionlog:API_URL + "pms/homePage/list/promotion/log",//活動人员信息
		deletepromotionlog:API_URL + "pms/homePage/delete/promotion/log",//删除活动人员
		getrefundOrderCount:API_URL + "pms/refundOrderCount",//待退款数量
		bookingOrderRefund:API_URL + "pms/bookingOrderRefund",//自动退款
		bookingOrderManualRefund:API_URL + "pms/bookingOrderManualRefund",//手动退款
		saveorg:API_URL + "org/save",//保存组织
		getorglist:API_URL + "org/list",//组织列表
		orghotellist:API_URL + "org/hotel/list",//组织下房源
		orghoteladd:API_URL + "org/hotel/add",//追加酒店
		deleteorghotel:API_URL + "org/hotel/delete",//删除房源
		getorgadminlist:API_URL + "org/admin/list",//获取组织成员
		deleteorgmem:API_URL + "org/admin/delete",//删除成员
		orgAdminadd:API_URL + "org/admin/add",//添加成员
		gethotelAll:API_URL + "pms/hotelAll",//获取所有房源
		saveadmin:API_URL + "admin/save",//追加管理员
		getEmaillistByHotelId:API_URL + "pms/hotelEmailTemp/listByHotelId",//获取房源下邮件模板
		gettempParam:API_URL + "pms/hotelEmailTemp/tempParam",//获取参数
		savehotelEmailTemp:API_URL + "pms/hotelEmailTemp/save",//保存模板
		gethomePageImage:API_URL + "pms/homePageImage/list",//获取图片列表
		getOrderAffiliatelist:API_URL + "orderAffiliate/list",//获取附属订单列表
		addorderAffiliate:API_URL + "orderAffiliate/add",//添加附属订单
		updateorderAffiliate:API_URL + "orderAffiliate/update",//编辑订单
		deleteorderAffiliate:API_URL + "orderAffiliate/delete",//删除
		getorderFinance:API_URL + "orderFinance/list",//财务对账
		exportorderFinance:API_URL + "orderFinance/list/excel",//导出
		orderFinancereceived:API_URL + "orderFinance/received",//标记
		getrepeatOrderCount:API_URL + "pms/repeatOrderCount",//获取重复订单
		exportbookingorder:API_URL + "pms/bookingorder/list/excel",//导出订单
		changehotelRecommend:API_URL + "pms/hotelRecommend",//房源推荐
		getstatistics:API_URL + "statistics/order/statistics",//统计图表
		getoverviewstatistics:API_URL + "statistics/order/statistics/overview",//统计概览
		getstatisticstable:API_URL + "statistics/order/statistics/table",//获取表格数据
		getstockalert:API_URL + "pms/admin/hotel/stock/alert",//获取预警
		gethotelsByarea:API_URL + "pms/hotelsByarea",//获取区域下酒店
		cleanTaskGenerate:API_URL + "pms/cleanTaskGenerate",//生成预任务
		cleanTaskGenerateView:API_URL + "pms/cleanTaskGenerateView",//预览预任务
		todayActualCleanTasks:API_URL + "pms/todayActualCleanTasks",//生成本日任务
		todayActualCleanTasksView:API_URL + "pms/todayActualCleanTasksView",//预览本日任务
		getPartnerorderlist:API_URL + "pms/channelPartner/order/list",//分销订单列表
		cancelCanOrder:API_URL + "pms/channelPartner/order/cancel",//分销取消订单
		channelSaveorder:API_URL + "pms/channelPartner/order/save",//分销新增接口
		channelInventory:API_URL + "pms/channelPartner/inventory",//分类库存
		getChannelextraPerson:API_URL + "pms/channelPartner/order/room/extraPerson",//额外价格
		getChannelPartnercalendar:API_URL + "pms/channelPartner/hotelventorybydate",//分销商日历
		pay4order:API_URL + "pms/channelPartner/pay4order",//支付
		priceCycleOverwrite:API_URL + "pms/priceCycleOverwrite",//复制价格
		priceExecuteRecord:API_URL + "pms/priceOverwriteExecuteRecord",//执行失败列表
		recordDetail:API_URL + "pms/priceOverwriteExecuteRecordDetail",//明细
		executemonitor:API_URL + "pms/priceOverwriteExecuteMonitor",//执行进度
		cleanTaskShield:API_URL + "pms/cleanTaskShield",//屏蔽
		cleanTaskUnShield:API_URL + "pms/cleanTaskUnShield",//确认疑似任务
		pendingTodayCleanTasks:API_URL + "pms/pendingTodayCleanTasks",//获取疑似任务
		getweekreport:API_URL + "statistics/order/report",//周报
		getcreditCardlist:API_URL + "creditCard/employee/pay/list",//信用卡列表
		creditCardpay:API_URL + "creditCard/employee/pay",//信用卡扣款
		creditCardrefund:API_URL + "creditCard/employee/refund",//信用卡退款
		getprematureCancellation:API_URL + "pms/prematureCancellation",//获取中途取消订单
		confirmPrematureCancellation:API_URL + "pms/confirmPrematureCancellation",//确认取消订单
		roomTypeSalePause:API_URL + "pms/admin/hotel/sale/pause",//暂停销售
		roomTypeSaleresume:API_URL + "pms/admin/hotel/sale/resume",//恢复销售
		getNoSaleDates:API_URL + "pms/admin/hotel/sale/getNoSaleDates",//查询禁售日期
		orderRoomLock:API_URL + "pms/orderRoomLock",//锁定房间改变状态
		cancelorderroom:API_URL + "pms/cancel/order/room",//取消房间
		getunallocatedlist:API_URL + "pms/unallocated/order/list",//未分配订单
		creatBlockorder:API_URL + "pms/create/block/order",//创建组织订单
	}
